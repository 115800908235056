;(function(){
    const throttle = function(type, name, obj){
        obj = obj || window;
        let running = false;
        let func = function(){
            if (running){ return; }
            running = true;
            requestAnimationFrame(function(){
                obj.dispatchEvent(new CustomEvent(name));
                running = false;
            });
        };
        obj.addEventListener(type, func);
    };

    throttle('scroll', 'optimizedScroll');
}) ();

export function scrollprogress() {

    'use strict';

    //should only execute when full window and all page contents have loaded
    window.addEventListener('optimizedScroll', function() {

        const header = document.querySelector('body > header');
        const body = document.body, html = document.documentElement;
        const progress = document.querySelector('.scrollprogress');

        const pageHeight = Math.max( body.scrollHeight, body.offsetHeight,
            html.clientHeight, html.scrollHeight, html.offsetHeight );

        const scrollPercentage = (document.documentElement.scrollTop / (pageHeight  - window.innerHeight)) * 100;

        if (progress) {
            progress.style.width = scrollPercentage + '%';
        }

    });

    return 'scrollprogress';

}

export function headerscroll() {

    const header = document.querySelector('.page-header');
    const headerTop = header.querySelector('.page-header__top');
    const headerMain = header.querySelector('.page-header__main');
    const hero = document.querySelector('.introbanner');
    let scrollBreakpoint = 100;
    let scrollPos = 0;

    window.addEventListener('optimizedScroll', function () {

        if (hero) {
            scrollBreakpoint = hero.offsetHeight;
        }

        if (headerMain) {
            if (window.scrollY > scrollBreakpoint) {
                header.classList.add('scrolled');
            } else {
                header.classList.remove('scrolled');
            }
        }

        if (headerTop && header) {

            if ((document.body.getBoundingClientRect()).top < scrollPos)
                header.style.transform = 'translateY(-' + headerTop.offsetHeight + 'px)';
            else
                header.style.transform = '';
            // saves the new position for iteration.
            scrollPos = (document.body.getBoundingClientRect()).top;
        }
    });

    return 'headerscroll';

}
