// Import source styles
import '../scss/app.scss';

// Load External Libraries
import './bootstrap';
//import AOS from 'aos'
//import { jarallax, jarallaxVideo } from 'jarallax';
import { Fancybox } from "@fancyapps/ui";

// Local imports
import { validate } from './modules/_validate.mjs';
import { Disclosure } from "./modules/_disclosure.mjs";
import { navigation } from "./modules/_navigation.mjs";
import { offcanvas } from "./modules/_offcanvas.mjs";
import { headerscroll } from "./modules/_header.mjs";
import { heroSlider } from "./modules/_carousels.mjs";
import { partnersSlider } from "./modules/_carousels.mjs";
import { reviewsSlider } from "./modules/_carousels.mjs";
import { coachingSlider } from "./modules/_carousels.mjs";
import { marquee } from "./modules/_marquee";
import { Tablist } from "./modules/_tablist.mjs";
import { teamPanels } from "./modules/_teampanels.mjs";
import { albumSlider } from "./modules/_carousels.mjs";

const disclosure = new Disclosure();
const tablist = new Tablist();

document.addEventListener("DOMContentLoaded", function () {
    //AOS.init({startEvent: 'load', disable: 'mobile'});
    //jarallax(document.querySelectorAll('.jarallax'), { speed: 0.5 });
    //jarallaxVideo();
    validate();
    navigation();
    offcanvas();
    headerscroll();
    heroSlider();
    marquee();
    partnersSlider();
    reviewsSlider();
    coachingSlider();
    teamPanels();
    albumSlider();




});
