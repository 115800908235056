import { updateButtonStates } from "./_buttons.mjs";
import { setActiveStartItems } from "./_setActiveStartItems.mjs";
import {
    animationsList,
    collapseHeight,
    collapseWidth,
    expandHeight,
    expandWidth,
    fadeIn,
    fadeOut,
    transformIn,
    transformOut
} from "./_animations.mjs";

const reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)');
const missingAria = new TypeError("Disclosure control is missing an aria-controls attribute");
const emptyAria = new TypeError("The provided aria-controls attribute is empty");
const isRTL = document.documentElement.getAttribute('dir') === 'rtl';

    'use strict';

    function Tablist(options) {
        this.Init(options);
    }

    /**
     * Constructor
     * @Param object options
     * @return void
    */
    Tablist.prototype = {
        // Initialize Object variables
        Init: function (options) { // Initialization

            // Default selectors
            const defaultOptions = {
                contents: '.js-tabpanel', // Toggleable elements
                controls: '.js-tab' // Controls (buttons) used to toggle elements
            }

            // Merge default options with provided options
            this.options = Object.assign({}, defaultOptions, options);

            // Initialize Prototype variables
            this.contents = document.querySelectorAll(this.options.contents);
            this.controls = document.querySelectorAll(this.options.controls);

            // Initialize Start function
            this.Start();
        },

        /**
         * Start
         * @return void
        */
        // Executes on page load
        Start: function () { // Starting functionality

            const _this = this; // Put the current context of 'this' in a variable, so we can always pass it to the next function

            // Create local variables from the Object context
            const controls = this.controls;
            const contents = this.contents;

            contents.forEach(function (content) {
                content.setAttribute('tabindex', '0');
            });

            function onLoadHandler() {
                setActiveStartItems(contents, _this);
                _this.UpdateButtonStates(controls); // Jump to 'UpdateButtonStates' so we can set all the toggle buttons to the correct aria-expanded/disabled values
                _this.SetEvents(controls); // Jump to 'SetEvents'
            }

            if (document.readyState === 'complete') {
                // If the document is already loaded, execute immediately
                onLoadHandler();
            } else {
                // Otherwise, add a listener for the 'load' event
                window.addEventListener('load', onLoadHandler);
            }



        },

        /**
         * Update Button States
         * @return void
        */
        // Update the button states
        // todo: We might want to move this function to its own script file that deals with button-related events and import it instead. That way we can always re-use it for other scripts that rely on checking button states. In particular, tab lists use a nearly identical implementation pattern, the only difference being the use of aria-selected on the buttons instead of aria-expanded.
        UpdateButtonStates: function (controls) {
            setTimeout(function () { // We set a delay here to make sure all toggleable elements updated their states before the buttons themselves do

                //let isOpened = ($targetElement.hasClass('expanding') || $targetElement.hasClass('expanded')) && !$targetElement.hasClass('collapsing');

                const contentState = 'aria-selected'
                updateButtonStates(controls, contentState);

            });
        },

        /**
         * SetEvents
         * @return void
        */
        SetEvents: function (controls, tablistControls = null) {
            const _this = this;

            controls.forEach(function (control) {
                control.addEventListener('keydown', function (e) {
                    let tab = this;
                    const keyCode = e.which;
                    const tablistControls = tab.closest('.js-tablist').querySelectorAll(_this.options.controls);
                    const index = Array.from(tablistControls).indexOf(tab);

                    if (isRTL) {
                        // Reverse the order of tablistControls for RTL mode
                        Array.from(tablistControls).reverse();
                    }

                    switch (keyCode) {
                        case 35: // End key (go to last tab element)
                            e.preventDefault();
                            tab = tablistControls[tablistControls.length - 1]
                            tab.focus();
                            break;
                        case 36: // Home key (go to first tab element)
                            e.preventDefault();
                            tab = tablistControls[0]
                            tab.focus();
                            break;
                        case 37: // Left arrow key
                        case 38: // Up arrow key (go to previous tab element sibling)
                            e.preventDefault();
                            tab = isRTL ? tablistControls[index + 1] || tablistControls[0] : tablistControls[index - 1] || tablistControls[tablistControls.length - 1];
                            tab.focus();
                            break;
                        case 39: // Right arrow key
                        case 40: // Down arrow key (go to next tab element sibling)
                            e.preventDefault();
                            tab = isRTL ? tablistControls[index - 1] || tablistControls[tablistControls.length - 1] : tablistControls[index + 1] || tablistControls[0];
                            tab.focus();
                            break;
                    }

                    _this.fireEvent(tab);
                });

                control.addEventListener('click', function () {
                    const tab = this;
                    _this.fireEvent(tab);
                });
            });

        },

        /**
         * FireEvent
         * @return void
         */
        // Event handling logic
        fireEvent: function (control) {

            const _this = this;

            if (control.hasAttribute('aria-controls')) {

                if (control.getAttribute('aria-selected') === 'true') {
                    return;
                }

                const targetElements = control.getAttribute('aria-controls').split(' '); // Get the space-separated values of 'aria-controls' for the clicked button, and split them into separate strings in a 'targetElements' array
                const nonMatchingIds = []; // Array to store non-matching IDs

                if (control.getAttribute('aria-controls') !== '') {

                    for (let i = 0; i < targetElements.length; i++) { // Iterate through the 'targetElements' array

                        let content = document.getElementById(targetElements[i]); // Create a variable, 'content' (same as in the 'Start' function) for each item in targetElements, and set it to be equal to the element in the document with a matching ID.

                        if (!content) {
                            // If content is not found, add the ID to nonMatchingIds array
                            nonMatchingIds.push(targetElements[i]);
                        }

                        // Check if the 'content' variable actually contains any DOM elements
                        if (content) {

                            // Create a variable where we can store the type of animation that should be used
                            let animation = content.getAttribute('data-animate');
                            if (!animation) {
                                let parentElement = content.closest('[data-animate]');
                                if (parentElement) {
                                    animation = parentElement.getAttribute('data-animate');
                                }
                            }

                            // Debounce button presses when the toggleable element is still transitioning. This makes sure button states don't update and animations don't execute in quick succession.
                            if (content.classList.contains('transitioning')) {
                                return; // todo: Potential Duplication: If the fireEvent() function is called from multiple places within the widget. We may want a more centralized debounce somewhere
                            }

                            content.setAttribute('data-disclosure-content', 'open');


                            // If the corresponding element is not currently in a 'transitioning' state, we jump to the 'SetAnimation' function, passing with it the current element
                            if (!content.classList.contains('transitioning')) {
                                this.SetAnimation(content, animation);
                            }
                        }
                        if (nonMatchingIds.length > 0) {
                            console.warn('No matching elements found in the document for the following IDs provided in "aria-controls":', nonMatchingIds);
                        }
                    }
                } else {
                    throw emptyAria; // Throw an error if an element with [data-disclosure-control] has an empty 'aria-controls' attribute, and stop executing the rest of the script
                }
            } else {
                throw missingAria; // Throw an error if an element with [data-disclosure-control] is missing an 'aria-controls' attribute, and stop executing the rest of the script
            }

            // other contents

            const tablist = control.closest('.js-tablist'); // Get the nearest '.js-tablist' parent

            // Get all opened accordion panels whose id does not match with the aria-controls attribute of the clicked accordion header // todo: We don't have to worry about looping through multiple aria-controls attributes here because accordion headers can never open more than 1 item at a time, but we should probably throw a warning for headers that have more than 1 string anyway
            const otherContents = tablist.querySelectorAll('.js-tabpanel[data-disclosure-content=open]:not([id="' + control.getAttribute('aria-controls') + '"])');

            otherContents.forEach(function(otherContent) { // Iterate through all non-matching accordion items
                otherContent.setAttribute('data-disclosure-content', 'closed'); // Update their states to 'closed'
                otherContent.classList.remove('active');
                otherContent.classList.remove('transitioning');
                otherContent.removeAttribute('style');
            });

            _this.UpdateButtonStates(this.controls); // Update the control button states again once all the toggleable elements are done updating their states
        },

        /**
         * SetAnimation
         * @return void
         */
        // Animation handling
        SetAnimation: function (content, animation) {

            //make sure there is no fade-out

            //todo: fade, height and width transitions currently do not work on nested content with parents that are not visible. (Their scrollHeight/scrollWidth defaults to 0px because the parent is also 0px, and 'transitionend' does not trigger because the element is not rendered when the parent is not. We should eventually fix this, but the situations where something like this occurs is pretty sparse

            // Check the visitor's motion preferences. If nothing is specified, execute the animation that is specified for the element
            if (!reducedMotion.matches) {

                // The animation used for toggling the element from a fixed set of options. Uses the class 'active' because it's what our _animations.mjs functions use // todo: the 'active' class indicates items that are expanded here. In _disclosure.mjs, we are using the value of [data-disclosure-content]. We should probably not rely on 2 separate values for this
                switch (animation) {

                    // Expand or collapse the element's height
                    case animationsList.toggleHeight:
                        expandHeight(content);
                        break;

                    // Expand or collapse the element's width
                    case animationsList.toggleWidth:
                        expandWidth(content);
                        break;

                    // Fade the element in or out
                    case animationsList.toggleFade:
                        fadeIn(content);
                        break;

                    case animationsList.toggleTransform:
                        transformIn(content);
                        break;

                    // Any value that is not one of the other values within this switch statement (including null/empty) will assume that the element has no toggle animation, and apply the 'active' class to toggle the element's 'active' styles immediately.
                    // This is always the case for users that have their motion preferences set to reduced, because the check above the Switch Statement leaves the value of 'animation' as 'null'.
                    default:
                        content.classList.add('active');
                        break;
                }

            } else {
                // I want the 'default' case in the Switch-statement above to execute for reduced-motion
                content.classList.add('active');
            }
        }


    }

    export { Tablist };
