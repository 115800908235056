export function marquee() {

    const reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)');
    const marquees = document.querySelectorAll('.marquee');

    if (!reducedMotion.matches) {
        scrollMarquee();
    }

    function scrollMarquee() {
        marquees.forEach((marquee) => {
            marquee.setAttribute('data-animated', true);

            const inner = marquee.querySelector('.marquee__inner');
            const items = Array.from(inner.children);

            items.forEach(item => {
                const clone = item.cloneNode(true);
                clone.setAttribute('aria-hidden', true);
                inner.appendChild(clone);
            });
        });
    }

    return 'marquee';
}
