import { tns } from 'tiny-slider';


export function heroSlider() {
    let slider = document.getElementById('hero-slider');
    if (slider) {
        slider = tns({
            container: '#hero-slider',
            items: 1,
            gutter: 16,
            autoplay: false,
            nav: true,
            navPosition: 'bottom',
            mouseDrag: true,
            touch: true,
            controls: false,
            controlsPosition: 'bottom',
            controlsText: ['<span class="icon-basic icon-chevron-left-circled"></span>', '<span class="icon-basic icon-chevron-right-circled"></span>'],
        });

    }

    return 'heroSlider';

}


export function blogSlider() {
    let slider = document.getElementById('blog-slider');
    if (slider) {
        slider = tns({
            container: '#blog-slider',
            items: 1.125,
            gutter: 16,
            autoplay: false,
            nav: true,
            navPosition: 'bottom',
            mouseDrag: true,
            touch: true,
            controls: true,
            controlsPosition: 'bottom',
            controlsText: ['<span class="icon-basic icon-chevron-left-circled"></span>', '<span class="icon-basic icon-chevron-right-circled"></span>'],
            responsive: {
                576: {
                    items: 1.5
                },
                768: {
                    items: 2,
                },
                1024: {
                    items: 3
                }
            }
        });

    }

    return 'blogSlider';

}

export function partnersSlider() {
    let slider = document.getElementById('partners-slider');
    if (slider) {
        slider = tns({
            container: '#partners-slider',
            items: 3,
            gutter: 24,
            autoplay: true,
            autoplayButton: false,
            startIndex: 2,
            autoplayButtonOutput: '',
            controls: false,
            controlsPosition: 'bottom',
            controlsText: ['<span class="icon-basic icon-chevron-left-circled"></span>', '<span class="icon-basic icon-chevron-right-circled"></span>'],
            nav: true,
            mouseDrag: true,
            //autoplayHoverPause: true, //too buggy right now, but should be added.
            touch: true,
            responsive: {
                576: {
                    items: 4
                },
                768: {
                    items: 5,
                    center: true
                }
            }
        });
    }
    return 'partnersSlider';
}


export function reviewsSlider() {
    let slider = document.getElementById('reviews-slider');
    if (slider) {
        slider = tns({
            container: '#reviews-slider',
            items: 1,
            gutter: 16,
            autoplay: false,
            nav: true,
            navPosition: 'bottom',
            mouseDrag: true,
            touch: true,
            controls: false,
            controlsPosition: 'bottom',
            controlsText: ['<span class="icon-basic icon-chevron-left-circled"></span>', '<span class="icon-basic icon-chevron-right-circled"></span>'],
        });

    }

    return 'reviewsSlider';

}



export function coachingSlider() {
    let slider = document.getElementById('coaching-slider');
    if (slider) {
        slider = tns({
            container: '#coaching-slider',
            items: 1,
            gutter: 16,
            autoplay: false,
            nav: true,
            navPosition: 'bottom',
            mouseDrag: true,
            touch: true,
            controls: true,
            controlsPosition: 'bottom',
            controlsText: ['<svg xmlns="http://www.w3.org/2000/svg" width="20" height="37" viewBox="0 0 20 37" fill="none">\n' +
            '<path d="M20 36.9231L20 0L-8.06979e-07 18.4615L20 36.9231Z" fill="#FF6400"/>\n' +
            '</svg>', '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="37" viewBox="0 0 20 37" fill="none">\n' +
            '<path d="M-1.61396e-06 1.90735e-05L0 36.9231L20 18.4616L-1.61396e-06 1.90735e-05Z" fill="#FF6400"/>\n' +
            '</svg>'],
        });

    }

    return 'coachingSlider';

}


export function albumSlider() {
    let slider = document.getElementById('album-slider');
    if (slider) {
        slider = tns({
            container: '#album-slider',
            items: 3,
            gutter: 24,
            autoplay: true,
            autoplayButton: false,
            autoplayButtonOutput: '',
            controls: false,
            controlsPosition: 'bottom',
            controlsText: ['<span class="icon-basic icon-chevron-left-circled"></span>', '<span class="icon-basic icon-chevron-right-circled"></span>'],
            nav: true,
            mouseDrag: true,
            //autoplayHoverPause: true, //too buggy right now, but should be added.
            touch: true,
            responsive: {
                768: {
                    items: 4
                }
            }
        });
    }
    return 'albumSlider';
}
