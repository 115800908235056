export function teamPanels() {
    const teampanels = document.getElementById('team-tabpanels');

    if (teampanels) {
        let page = document.querySelector('.page-content');
        let teamcontrols = document.querySelectorAll('.team .modal-trigger');
        let closeButton = document.createElement('button');
        let backdrop = document.createElement('div');

        teamcontrols.forEach(function (teamcontrol) {
            teamcontrol.addEventListener('click', function() {
                backdrop.classList.add('teampanel-backdrop');
                closeButton.classList.add('teampanel-close');
                closeButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="#fff"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>'
                teampanels.style.display = 'block';
                teampanels.style.zIndex = '10000';
                document.body.appendChild(closeButton);
                document.body.appendChild(backdrop);
                document.body.classList.add('inert');
                setTimeout(function() {
                    teampanels.style.opacity = '1';
                    backdrop.style.opacity = '1';
                }, 150);
            });
        });

        function closeTeampanel() {

            teampanels.style.opacity = '0';
            backdrop.style.opacity = '0';
            setTimeout(function() {
                teampanels.style.display = 'none';
                teampanels.style.zIndex = '';
                backdrop.remove();
                closeButton.remove();
                document.body.classList.remove('inert');
            }, 150);
        }

        closeButton.addEventListener('click', function() {
            closeTeampanel();
        });


        teampanels.addEventListener('click', function (e) {
            const target = e.target;
            const isTabpanel = target.closest('.js-tabpanel');
            if (!isTabpanel) {
                closeTeampanel();
            }
        });
    }


    return 'teamPanels';
}
